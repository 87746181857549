import request from '@/utils/request'

// 用户列表
export function partnerAccountTwoPage(query) {
  return request({
    url: '/orgUserGinseng/listPartnerPage',
    method: 'get',
    params: query
  })
}
// 用于用户新增选择角色(合作伙伴)
export function listRoleGinseng(query) {
  return request({
    url: '/orgRoleGinseng/listRolePartner',
    method: 'get',
    params: query
  })
}
//新增(合作伙伴)
export function insertUser(data) {
  return request({
    url: '/orgUserGinseng/saveGinseng',
    method: 'post',
    data
  })
}
//修改(合作伙伴)
export function updateUsers(data) {
  return request({
    url: '/orgUserGinseng/updateGinseng',
    method: 'post',
    data
  })
}
// 账号启用/禁用
export function notState(query) {
  return request({
    url: '/orgUserGinseng/notState',
    method: 'get',
    params: query
  })
}
// 账号冻结/解冻
export function notDelete(query) {
  return request({
    url: '/orgUserGinseng/notDelete',
    method: 'get',
    params: query
  })
}
// 查询用户详情
export function accoutlistById(query) {
  return request({
    url: '/orgUserGinseng/listById',
    method: 'get',
    params: query
  })
}
// 删除用户
export function deleteById(query) {
  return request({
    url: `/orgUserGinseng/${query}`,
    method: "DELETE",
    params: query
  })
}
